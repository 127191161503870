import {
  ADD_ADMIN_PERMISSION_MUTATION,
  ADD_OPERATOR_ECOSYSTEM_MUTATION,
  ADD_PASS_ECOSYSTEMS_MUTATION,
  ADJUST_RIDE_MUTATION,
  ADMINS_QUERY,
  ADMIN_PERMISSIONS_QUERY,
  ADMIN_PROFILE_QUERY,
  ADMIN_QUERY,
  ASSIGN_TASKS_MUTATION,
  AUDIT_OPERATOR_LOG_MUTATION,
  BIKES_QUERY,
  BLOCK_ADMIN_MUTATION,
  BLOCK_OPERATOR_MUTATION,
  BLOCK_USER_MUTATION,
  CAPTURE_RAZORPAY_PAYMENT_MUTATION,
  COMPLETED_RIDES_QUERY,
  CREATE_ADMIN_PERMISSION_MUTATION,
  CREATE_BIKE_MUTATION,
  CREATE_DEBIT_PAYMENT_MUTATION,
  CREATE_DOCK_MUTATION,
  CREATE_ECOSYSTEM_MUTATION,
  CREATE_OPERATOR_MUTATION,
  CREATE_PASS_MUTATION,
  CREATE_STATION_MUTATION,
  CREATE_TASK_MUTATION,
  CREATE_VOUCHER_PAYMENT_MUTATION,
  CUMULATIVE_ANALYTICS_QUERY,
  DELETE_ALL_PENDING_BIKES_MUTATION,
  DELETE_DOCK_MUTATION,
  DELETE_ONGOING_RIDE_MUTATION,
  DELETE_OPERATOR_ECOSYSTEM_MUTATION,
  DELETE_PENDING_BIKE_MUTATION,
  DELETE_TASKS_MUTATION,
  DEPLOY_PENDING_BIKE_MUTATION,
  DOCKS_QUERY,
  DOCK_QUERY,
  ECOSYSTEMS_QUERY,
  ECOSYSTEMS_WITHOUT_PASSES_QUERY,
  LOGIN_MUTATION,
  ONGOING_RIDES_QUERY,
  OPERATORS_QUERY,
  OPERATOR_LOGS_QUERY,
  OPERATOR_LOG_FOR_AUDIT_QUERY,
  OPERATOR_PROFILE_QUERY,
  PAGINATED_ECOSYSTEMS_QUERY,
  PASSES_QUERY,
  PASS_QUERY,
  PAYMENTS_QUERY,
  PAYMENT_QUERY,
  PENDING_BIKES_QUERY,
  REFERRALS_QUERY,
  REFUND_ONLINE_PAYMENT_MUTATION,
  REFUND_PASS_PURCHASE_MUTATION,
  REFUND_RIDE_MUTATION,
  REMOVE_ADMIN_PERMISSION_MUTATION,
  REMOVE_PASS_ECOSYSTEMS_MUTATION,
  RESTART_DOCK,
  REWARDS_QUERY,
  RIDE_RATINGS_QUERY,
  STATIONS_QUERY,
  STOP_RIDE_MUTATION,
  STOP_RIDE_STATIONS_QUERY,
  STORE_DEALS_QUERY,
  STORE_DEAL_QUERY,
  TASKS_QUERY,
  TASKS_STATS_QUERY,
  TASK_ISSUES_QUERY,
  TASK_PHOTO_URL_MUTATION,
  TOGGLE_PASS_STATUS_MUTATION,
  TOGGLE_TEST_USER_MUTATION,
  TRIGGER_DOCK,
  UNBLOCK_ADMIN_MUTATION,
  UNBLOCK_OPERATOR_MUTATION,
  UNBLOCK_USER_MUTATION,
  UPDATE_BIKE_ECOSYSTEM_MUTATION,
  UPDATE_BIKE_HEXCODE_MUTATION,
  UPDATE_BIKE_STATE_MUTATION,
  UPDATE_DOCK_MUTATION,
  UPDATE_DOCK_STATUS_MUTATION,
  UPDATE_ECOSYSTEM_MUTATION,
  UPDATE_OPERATOR_ROLE_MUTATION,
  UPDATE_STATION_DETAILS_MUTATION,
  UPDATE_TASK_MUTATION,
  USERS_QUERY,
  USER_PROFILE_QUERY,
} from './graphql.js'
import request from './utils/request.js'

export default {
  // auth
  async login({ commit }, payload) {
    const response = await request(LOGIN_MUTATION, { input: payload })

    if (response?.data?.loginAdmin) {
      commit('SET_ADMIN_SESSION', response.data.loginAdmin)
      return response.data.loginAdmin
    }
  },
  async refreshAdmin({ commit, state }) {
    const response = await request(ADMIN_PROFILE_QUERY, {}, state.token)

    if (response?.data?.currentAdmin) {
      commit('REFRESH_ADMIN_SESSION', response.data.currentAdmin)
      return response.data.currentAdmin
    }
  },
  // ecosystems
  async getEcosystems({ commit, state }) {
    const response = await request(ECOSYSTEMS_QUERY, {}, state.token)

    if (response?.data?.ecosystems) {
      commit('SET_ECOSYSTEMS', response.data.ecosystems)
      return response.data.ecosystems
    }
  },
  // stations
  async getStations({ state }, payload) {
    const response = await request(STATIONS_QUERY, payload, state.token)

    if (response?.data?.stations) {
      return response.data.stations
    }
  },
  async getDock({ state }, payload) {
    const response = await request(DOCK_QUERY, payload, state.token)

    if (response?.data?.dock) {
      return response.data.dock
    }
  },
  async updateStationDetails({ state }, payload) {
    const response = await request(
      UPDATE_STATION_DETAILS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateStationDetails) {
      return response.data.updateStationDetails
    }
  },
  async restartDock({ state }, payload) {
    const response = await request(
      RESTART_DOCK,
      { input: payload },
      state.token,
    )

    if (response?.data?.restartDock) {
      return response.data.restartDock
    }
  },
  async triggerDock({ state }, payload) {
    const response = await request(
      TRIGGER_DOCK,
      { input: payload },
      state.token,
    )

    if (response?.data?.triggerDock) {
      return response.data.triggerDock
    }
  },
  async createStation({ state }, payload) {
    const response = await request(
      CREATE_STATION_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createStation) {
      return response.data.createStation
    }
  },
  async updateDockStatus({ state }, payload) {
    const response = await request(
      UPDATE_DOCK_STATUS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateDockStatus) {
      return response.data.updateDockStatus
    }
  },
  // ongoing rides
  async getOngoingRides({ state }, payload) {
    const response = await request(ONGOING_RIDES_QUERY, payload, state.token)

    if (response?.data?.ongoingRides) {
      return response.data.ongoingRides
    }
  },
  async getStopStations({ state }, payload) {
    const response = await request(
      STOP_RIDE_STATIONS_QUERY,
      payload,
      state.token,
    )

    if (response?.data?.stations) {
      return response.data.stations
    }
  },
  async stopRide({ state }, payload) {
    const response = await request(
      STOP_RIDE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.stopOngoingRide) {
      return response.data.stopOngoingRide
    }
  },
  async deleteOngoingRide({ state }, payload) {
    const response = await request(
      DELETE_ONGOING_RIDE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deleteOngoingRide) {
      return response.data.deleteOngoingRide
    }
  },
  // completed rides
  async getCompletedRides({ state }, payload) {
    const response = await request(COMPLETED_RIDES_QUERY, payload, state.token)

    if (response?.data?.rides) {
      return response.data.rides
    }
  },
  async adjustRide({ state }, payload) {
    const response = await request(
      ADJUST_RIDE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.adjustRide) {
      return response.data.adjustRide
    }
  },
  async refundRide({ state }, payload) {
    const response = await request(
      REFUND_RIDE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.refundRide) {
      return response.data.refundRide
    }
  },
  // users
  async getUsers({ state }, payload) {
    const response = await request(USERS_QUERY, payload, state.token)

    if (response?.data?.users) {
      return response.data.users
    }
  },
  async toggleTestUser({ state }, payload) {
    const response = await request(
      TOGGLE_TEST_USER_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.toggleTestUser) {
      return response.data.toggleTestUser
    }
  },
  async blockUser({ state }, payload) {
    const response = await request(
      BLOCK_USER_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.blockUser) {
      return response.data.blockUser
    }
  },
  async unblockUser({ state }, payload) {
    const response = await request(
      UNBLOCK_USER_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.unblockUser) {
      return response.data.unblockUser
    }
  },
  async getUserProfile({ state }, payload) {
    const response = await request(USER_PROFILE_QUERY, payload, state.token)

    if (response?.data?.users) {
      return response.data.users
    }
  },
  async createVoucherPayment({ state }, payload) {
    const response = await request(
      CREATE_VOUCHER_PAYMENT_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createVoucherPayment) {
      return response.data.createVoucherPayment
    }
  },
  async createDebitPayment({ state }, payload) {
    const response = await request(
      CREATE_DEBIT_PAYMENT_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createDebitPayment) {
      return response.data.createDebitPayment
    }
  },
  // payments
  async getPayments({ state }, payload) {
    const response = await request(PAYMENTS_QUERY, payload, state.token)

    if (response?.data?.payments) {
      return response.data.payments
    }
  },
  async getPayment({ state }, payload) {
    const response = await request(PAYMENT_QUERY, payload, state.token)

    if (response?.data?.payment) {
      return response.data.payment
    }
  },
  async captureRazorpayPayment({ state }, payload) {
    const response = await request(
      CAPTURE_RAZORPAY_PAYMENT_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.captureRazorpayPayment) {
      return response.data.captureRazorpayPayment
    }
  },
  async refundOnlinePayment({ state }, payload) {
    const response = await request(
      REFUND_ONLINE_PAYMENT_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.refundOnlinePayment) {
      return response.data.refundOnlinePayment
    }
  },
  async refundPassPurchase({ state }, payload) {
    const response = await request(
      REFUND_PASS_PURCHASE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.refundPassPurchase) {
      return response.data.refundPassPurchase
    }
  },
  // operator logs
  async getOperatorLogs({ state }, payload) {
    const response = await request(OPERATOR_LOGS_QUERY, payload, state.token)

    if (response?.data?.operatorLogs) {
      return response.data.operatorLogs
    }
  },
  // bikes
  async getBikes({ state }, payload) {
    const response = await request(BIKES_QUERY, payload, state.token)

    if (response?.data?.bikes) {
      return response.data.bikes
    }
  },
  async updateBikeHexcode({ state }, payload) {
    const response = await request(
      UPDATE_BIKE_HEXCODE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateBikeHexcode) {
      return response.data.updateBikeHexcode
    }
  },
  async updateBikeState({ state }, payload) {
    const response = await request(
      UPDATE_BIKE_STATE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateBikeState) {
      return response.data.updateBikeState
    }
  },
  async updateBikeEcosystem({ state }, payload) {
    const response = await request(
      UPDATE_BIKE_ECOSYSTEM_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateBikeEcosystem) {
      return response.data.updateBikeEcosystem
    }
  },
  // pending bikes
  async getPendingBikes({ state }, payload) {
    const response = await request(PENDING_BIKES_QUERY, payload, state.token)

    if (response?.data?.pendingBikes) {
      return response.data.pendingBikes
    }
  },
  async deleteAllPendingBikes({ state }, payload) {
    const response = await request(
      DELETE_ALL_PENDING_BIKES_MUTATION,
      payload,
      state.token,
    )

    if (response?.data?.deleteAllPendingBikes) {
      return response.data.deleteAllPendingBikes
    }
  },
  async deployPendingBike({ state }, payload) {
    const response = await request(
      DEPLOY_PENDING_BIKE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deployPendingBike) {
      return response.data.deployPendingBike
    }
  },
  async deletePendingBike({ state }, payload) {
    const response = await request(
      DELETE_PENDING_BIKE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deletePendingBike) {
      return response.data.deletePendingBike
    }
  },
  async createBike({ state }, payload) {
    const response = await request(
      CREATE_BIKE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createBike) {
      return response.data.createBike
    }
  },
  // docks
  async getDocks({ state }, payload) {
    const response = await request(DOCKS_QUERY, payload, state.token)

    if (response?.data?.docks) {
      return response.data.docks
    }
  },
  async createDock({ state }, payload) {
    const response = await request(
      CREATE_DOCK_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createDock) {
      return response.data.createDock
    }
  },
  async updateDock({ state }, payload) {
    const response = await request(
      UPDATE_DOCK_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateDock) {
      return response.data.updateDock
    }
  },
  async deleteDock({ state }, payload) {
    const response = await request(
      DELETE_DOCK_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deleteDock) {
      return response.data.deleteDock
    }
  },
  // ride ratings
  async getRideRatings({ state }, payload) {
    const response = await request(RIDE_RATINGS_QUERY, payload, state.token)

    if (response?.data?.rideRatings) {
      return response.data.rideRatings
    }
  },
  // tasks
  async getTasks({ state }, payload) {
    const response = await request(TASKS_QUERY, payload, state.token)

    if (response?.data?.tasks) {
      return response.data.tasks
    }
  },
  async getTaskIssues({ state }, payload) {
    const response = await request(TASK_ISSUES_QUERY, payload, state.token)

    if (response?.data?.taskIssues) {
      return response.data.taskIssues
    }
  },
  async getTasksStats({ state }, payload) {
    const response = await request(TASKS_STATS_QUERY, payload, state.token)

    if (response?.data?.tasksStats) {
      return response.data.tasksStats
    }
  },
  async createTask({ state }, payload) {
    const response = await request(
      CREATE_TASK_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createTask) {
      return response.data.createTask
    }
  },
  async createTaskPhotoUrl({ state }, payload) {
    const response = await request(
      TASK_PHOTO_URL_MUTATION,
      payload,
      state.token,
    )

    if (response?.data?.createTaskPhotoUrl) {
      return response.data.createTaskPhotoUrl
    }
  },
  async updateTask({ state }, payload) {
    const response = await request(
      UPDATE_TASK_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateTask) {
      return response.data.updateTask
    }
  },
  async assignTasks({ state }, payload) {
    const response = await request(
      ASSIGN_TASKS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.assignTasks) {
      return response.data.assignTasks
    }
  },
  async deleteTasks({ state }, payload) {
    const response = await request(
      DELETE_TASKS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deleteTasks) {
      return response.data.deleteTasks
    }
  },
  // referrals
  async getReferrals({ state }, payload) {
    const response = await request(REFERRALS_QUERY, payload, state.token)

    if (response?.data?.referrals) {
      return response.data.referrals
    }
  },
  // rewards
  async getRewards({ state }, payload) {
    const response = await request(REWARDS_QUERY, payload, state.token)

    if (response?.data?.rewards) {
      return response.data.rewards
    }
  },
  // analytics
  async getCumulativeAnalytics({ state }, payload) {
    const response = await request(
      CUMULATIVE_ANALYTICS_QUERY,
      payload,
      state.token,
    )

    if (response?.data.analytics) {
      return response.data.analytics
    }
  },
  // operators
  async getOperators({ state }, payload) {
    const response = await request(OPERATORS_QUERY, payload, state.token)

    if (response?.data?.operators) {
      return response.data.operators
    }
  },
  async updateOperatorRole({ state }, payload) {
    const response = await request(
      UPDATE_OPERATOR_ROLE_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateOperatorRole) {
      return response.data.updateOperatorRole
    }
  },
  async createOperator({ state }, payload) {
    const response = await request(
      CREATE_OPERATOR_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createOperator) {
      return response.data.createOperator
    }
  },
  // operator profile
  async getOperatorProfile({ state }, payload) {
    const response = await request(OPERATOR_PROFILE_QUERY, payload, state.token)

    if (response?.data?.operators) {
      return response.data.operators
    }
  },
  async blockOperator({ state }, payload) {
    const response = await request(
      BLOCK_OPERATOR_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.blockOperator) {
      return response.data.blockOperator
    }
  },
  async unblockOperator({ state }, payload) {
    const response = await request(
      UNBLOCK_OPERATOR_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.unblockOperator) {
      return response.data.unblockOperator
    }
  },
  async addOperatorEcosystem({ state }, payload) {
    const response = await request(
      ADD_OPERATOR_ECOSYSTEM_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.addOperatorEcosystem) {
      return response.data.addOperatorEcosystem
    }
  },
  async deleteOperatorEcosystem({ state }, payload) {
    const response = await request(
      DELETE_OPERATOR_ECOSYSTEM_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.deleteOperatorEcosystem) {
      return response.data.deleteOperatorEcosystem
    }
  },
  // passes
  async getPasses({ state }, payload) {
    const response = await request(PASSES_QUERY, payload, state.token)

    if (response?.data?.passes) {
      return response.data.passes
    }
  },
  async getPass({ state }, payload) {
    const response = await request(PASS_QUERY, payload, state.token)

    if (response?.data?.pass) {
      return response.data.pass
    }
  },
  async getEcosystemsWithoutPasses({ state }, payload) {
    const response = await request(
      ECOSYSTEMS_WITHOUT_PASSES_QUERY,
      payload,
      state.token,
    )

    if (response?.data?.ecosystemsWithoutPasses) {
      return response.data.ecosystemsWithoutPasses
    }
  },
  async createPass({ state }, payload) {
    const response = await request(
      CREATE_PASS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createPass) {
      return response.data.createPass
    }
  },
  async togglePassStatus({ state }, payload) {
    const response = await request(
      TOGGLE_PASS_STATUS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.togglePassStatus) {
      return response.data.togglePassStatus
    }
  },
  async addPassEcosystems({ state }, payload) {
    const response = await request(
      ADD_PASS_ECOSYSTEMS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.addPassEcosystems) {
      return response.data.addPassEcosystems
    }
  },
  async removePassEcosystems({ state }, payload) {
    const response = await request(
      REMOVE_PASS_ECOSYSTEMS_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.removePassEcosystems) {
      return response.data.removePassEcosystems
    }
  },
  // ecosystems
  async getPaginatedEcosystems({ state }, payload) {
    const response = await request(
      PAGINATED_ECOSYSTEMS_QUERY,
      payload,
      state.token,
    )

    if (response?.data?.paginatedEcosystems) {
      return response.data.paginatedEcosystems
    }
  },
  async createEcosystem({ state }, payload) {
    const response = await request(
      CREATE_ECOSYSTEM_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createEcosystem) {
      return response.data.createEcosystem
    }
  },
  async updateEcosystem({ state }, payload) {
    const response = await request(
      UPDATE_ECOSYSTEM_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.updateEcosystem) {
      return response.data.updateEcosystem
    }
  },
  // audit operator logs
  async getOperatorLogForAudit({ state }, payload) {
    const response = await request(
      OPERATOR_LOG_FOR_AUDIT_QUERY,
      payload,
      state.token,
    )

    if (response?.data?.operatorLogForAudit) {
      return response.data.operatorLogForAudit
    }
  },
  async auditOperatorLog({ state }, payload) {
    const response = await request(
      AUDIT_OPERATOR_LOG_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.auditOperatorLog) {
      return response.data.auditOperatorLog
    }
  },
  // store deals
  async getStoreDeals({ state }, payload) {
    const response = await request(STORE_DEALS_QUERY, payload, state.token)

    if (response?.data?.storeDeals) {
      return response.data.storeDeals
    }
  },
  async getStoreDeal({ state }, payload) {
    const response = await request(STORE_DEAL_QUERY, payload, state.token)

    if (response?.data?.storeDeal) {
      return response.data.storeDeal
    }
  },
  // admins
  async getAdmins({ state }, payload) {
    const response = await request(ADMINS_QUERY, payload, state.token)

    if (response?.data?.admins) {
      return response.data.admins
    }
  },
  async getAdminPermissions({ state }, payload) {
    const response = await request(
      ADMIN_PERMISSIONS_QUERY,
      payload,
      state.token,
    )

    if (response?.data?.adminPermissions) {
      return response.data.adminPermissions
    }
  },
  async createAdminPermission({ state }, payload) {
    const response = await request(
      CREATE_ADMIN_PERMISSION_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.createAdminPermission) {
      return response.data.createAdminPermission
    }
  },
  async getAdmin({ state }, payload) {
    const response = await request(ADMIN_QUERY, payload, state.token)

    if (response?.data?.admins) {
      return response.data.admins
    }
  },
  async addAdminPermission({ state }, payload) {
    const response = await request(
      ADD_ADMIN_PERMISSION_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.addAdminPermission) {
      return response.data.addAdminPermission
    }
  },
  async deleteAdminPermission({ state }, payload) {
    const response = await request(
      REMOVE_ADMIN_PERMISSION_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.removeAdminPermission) {
      return response.data.removeAdminPermission
    }
  },
  async blockAdmin({ state }, payload) {
    const response = await request(
      BLOCK_ADMIN_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.blockAdmin) {
      return response.data.blockAdmin
    }
  },
  async unblockAdmin({ state }, payload) {
    const response = await request(
      UNBLOCK_ADMIN_MUTATION,
      { input: payload },
      state.token,
    )

    if (response?.data?.unblockAdmin) {
      return response.data.unblockAdmin
    }
  },
  // logout
  logout({ commit }) {
    commit('CLEAR_STATE')
    commit('SHOW_SNACKBAR', { message: 'You have been logged out.' })
  },
}
