export default function initialState() {
  return {
    snackbar: {
      visible: false,
      timeout: 6000,
      message: null,
    },
    token: null,
    adminId: null,
    adminPermissions: [],
    ecosystems: [],
  }
}
