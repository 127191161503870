import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

// atomic guard rules
const disallowIfLogin = (_to, _from, next) => {
  if (store.getters.isLoggedIn) {
    next({ name: 'stations' })
  } else {
    next()
  }
}
const requireLogin = (_to, _from, next) => {
  if (!store.getters.isLoggedIn) {
    next({ name: 'login' })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: disallowIfLogin,
    },
    {
      path: '/app',
      component: () => import('@/views/MainApp.vue'),
      beforeEnter: requireLogin,
      redirect: '/app/stations',
      children: [
        {
          path: 'stations',
          name: 'stations',
          component: () => import('@/views/Stations.vue'),
        },
        {
          path: 'ongoing-rides',
          name: 'ongoing-rides',
          component: () => import('@/views/OngoingRides.vue'),
        },
        {
          path: 'completed-rides',
          name: 'completed-rides',
          component: () => import('@/views/CompletedRides.vue'),
        },
        {
          path: 'users',
          name: 'users',
          component: () => import('@/views/Users.vue'),
        },
        {
          path: 'user/:id',
          name: 'user',
          component: () => import('@/views/UserProfile.vue'),
        },
        {
          path: 'payments',
          name: 'payments',
          component: () => import('@/views/Payments.vue'),
        },
        {
          path: 'operator-logs',
          name: 'operator-logs',
          component: () => import('@/views/OperatorLogs.vue'),
        },
        {
          path: 'referrals',
          name: 'referrals',
          component: () => import('@/views/Referrals.vue'),
        },
        {
          path: 'rewards',
          name: 'rewards',
          component: () => import('@/views/Rewards.vue'),
        },
        {
          path: 'analytics',
          name: 'analytics',
          component: () => import('@/views/Analytics.vue'),
        },
        {
          path: 'docks',
          name: 'docks',
          component: () => import('@/views/Docks.vue'),
        },
        {
          path: 'bikes',
          component: () => import('@/views/BikesMain.vue'),
          children: [
            {
              path: 'view',
              name: 'view-bikes',
              component: () => import('@/views/BikesView.vue'),
            },
            {
              path: 'pending',
              name: 'pending-bikes',
              component: () => import('@/views/BikesPending.vue'),
            },
          ],
        },
        {
          path: 'operators',
          name: 'operators',
          component: () => import('@/views/Operators.vue'),
        },
        {
          path: 'operator/:id',
          name: 'operator',
          component: () => import('@/views/OperatorProfile.vue'),
        },
        {
          path: 'ride-ratings',
          name: 'ride-ratings',
          component: () => import('@/views/RideRatings.vue'),
        },
        {
          path: 'tasks',
          name: 'tasks',
          component: () => import('@/views/Tasks.vue'),
        },
        {
          path: 'passes',
          name: 'passes',
          component: () => import('@/views/Passes.vue'),
        },
        {
          path: 'store-deals',
          name: 'store-deals',
          component: () => import('@/views/StoreDeals.vue'),
        },
        {
          path: 'ecosystems',
          name: 'ecosystems',
          component: () => import('@/views/Ecosystems.vue'),
        },
        {
          path: 'audit',
          name: 'audit',
          component: () => import('@/views/AuditOperatorLogs.vue'),
        },
        {
          path: 'admins',
          name: 'admins',
          component: () => import('@/views/Admins.vue'),
        },
        {
          path: 'session-expired',
          name: 'session-expired',
          component: () => import('@/views/SessionExpired.vue'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
